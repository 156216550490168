var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"troca_senha"},[_c('ValidationObserver',{ref:"form",staticClass:"modal_login row",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.loginStep($event)}}},[_c('div',{staticClass:"col-md-12 my-1"},[_c('label',{staticClass:"label_default"},[_vm._v(" Digite sua "),_c('strong',[_vm._v("nova senha")])])]),_c('ValidationProvider',{staticClass:"col-md-12 my-1",attrs:{"rules":"required|confirmed:confirm|min:5","name":"senha","tag":"div","bails":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.senha),expression:"senha"}],staticClass:"px-2 py-2 input_default form-control",class:classes,attrs:{"type":"password"},domProps:{"value":(_vm.senha)},on:{"input":function($event){if($event.target.composing){ return; }_vm.senha=$event.target.value}}}),_c('span',_vm._b({class:classes},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}])}),_c('div',{staticClass:"col-md-12 my-1"},[_c('label',{staticClass:"label_default"},[_vm._v(" Confirme sua "),_c('strong',[_vm._v("nova senha")])])]),_c('ValidationProvider',{staticClass:"col-md-12 my-1",attrs:{"rules":"required","name":"Confirma","tag":"div","bails":false,"vid":"confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirm),expression:"confirm"}],staticClass:"px-2 py-2 input_default form-control",class:classes,attrs:{"type":"password"},domProps:{"value":(_vm.confirm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirm=$event.target.value}}}),_c('span',_vm._b({class:classes},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}])}),_c('div',{staticClass:"col-md-12 my-1"},[_c('button',{staticClass:"btn_default px-2 py-2",attrs:{"type":"submit","disabled":_vm.disabled}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.disabled),expression:"disabled"}],staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}),_vm._v(" "+_vm._s(!_vm.disabled ? "Validar" : "")+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }