<template>
  <div class="troca_senha">
    <ValidationObserver class="modal_login row" tag="form" @submit.prevent="loginStep" ref="form">
      <div class="col-md-12 my-1">
        <label class="label_default">
          Digite sua
          <strong>nova senha</strong>
        </label>
      </div>

      <ValidationProvider
        v-slot="{ errors, ariaMsg, classes }"
        rules="required|confirmed:confirm|min:5"
        name="senha"
        tag="div"
        :bails="false"
        class="col-md-12 my-1"
      >
        <input
          type="password"
          class="px-2 py-2 input_default form-control"
          v-model="senha"
          :class="classes"
        />
        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </ValidationProvider>
      <div class="col-md-12 my-1">
        <label class="label_default">
          Confirme sua
          <strong>nova senha</strong>
        </label>
      </div>

      <ValidationProvider
        v-slot="{ errors, ariaMsg, classes }"
        rules="required"
        name="Confirma"
        tag="div"
        :bails="false"
        vid="confirm"
        class="col-md-12 my-1"
      >
        <input
          type="password"
          class="px-2 py-2 input_default form-control"
          v-model="confirm"
          :class="classes"
        />
        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </ValidationProvider>
      <div class="col-md-12 my-1">
        <button type="submit" class="btn_default px-2 py-2" :disabled="disabled">
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
            v-show="disabled"
          ></span>
          {{ !disabled ? "Validar" : "" }}
        </button>
      </div>
    </ValidationObserver>
   
  </div>
</template>

<script>
export default {
  data() {
    return {
      senha: "",
      confirm: "",
      disabled: false
    };
  },
  methods: {
    loginStep() {
      this.$refs.form.validate().then(success => {
        if (success) {
          this.disabled = true;
          this.$store.dispatch("trocaPassword", this.senha).then(resolve => {
            if (resolve) {
              this.$notify({
                group: "erros",
                type: "sucess",
                text: `<i class="icon ion-close-circled"></i>Senha alterada com sucesso`
              });
              this.$router.push("/senha");
              this.disabled = false;
              this.$parent.gratientAnimator(
                "linear-gradient(to bottom, #e0a638 0%, #f38235 100%)"
              );
            }
          });
        }
      });
    }
  }
};
</script>

<style>
</style>